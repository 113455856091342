import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider, GithubAuthProvider } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';


const firebaseConfig = {
  apiKey: "AIzaSyATVCSdnDCqfEPNTmAjIPihw9q5iucvpkM",
  authDomain: "codewithmuhilan-a072f.firebaseapp.com",
  projectId: "codewithmuhilan-a072f",
  storageBucket: "codewithmuhilan-a072f.appspot.com",
  messagingSenderId: "575512491038",
  appId: "1:575512491038:web:ab214dbff2ad3f4fcaea0f",
  measurementId: "G-NLHYYZJ6DG"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

// Add providers
const googleProvider = new GoogleAuthProvider();
const githubProvider = new GithubAuthProvider();

export { auth, db, googleProvider, githubProvider };