import React from 'react';
import { Routes, Route } from 'react-router-dom';
import FirebaseLogin from './FirebaseLogin';
// Import other components as needed

const App = () => (
  <Routes>
    <Route path="/" element={<FirebaseLogin />} />
    {/* Add other routes here */}
  </Routes>
);

export default App;
